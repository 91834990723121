import React from "react";
import Layout from "../components/layout";
import styled from "styled-components";
import defaultImg from "../images/header_test.png";

const contactEl = styled.div``;

const contact = () => {
  return (
    <Layout
      heroText={"contact"}
      heroSubText={"hello@everyfitgirl.co"}
      headerImage={defaultImg}
    >
      <contactEl />
    </Layout>
  );
};

export default contact;
